'use client'

import React, { useEffect, useState } from 'react'
import StackedCards from '@/components/global/StackedCards';
import { plurification } from '@/lib/helpers';
import { useContext } from 'react';
import { AuthContext } from '@/lib/contexts/authContext';
import { SDKWrapper } from '@/lib/helpers/directus/SDKWrapper';

import { Button, Card, Icon } from "@/components/capacitor";
import { roles } from '@/lib/constants/users';
import { useRouter } from 'next/navigation';
import Bugsnag from '@bugsnag/js';


export default function AdminDashboard({ user }) {
    const { localStorageObject, setLocalStorageObject } = useContext(AuthContext);
    const sdkWrapper = new SDKWrapper(localStorageObject, setLocalStorageObject);

    const router = useRouter()

    const [levelData, setLevelData] = useState(null);
    
    const [totalUsers, setTotalUsers] = useState(0);
    const [adminCount, setAdminCount] = useState(0);
    const [userCount, setUserCount] = useState(0);

    useEffect(() => {
        async function fetchUsers() {
            const usersData = await sdkWrapper.getUsers();
            if (usersData) {
                const admins = usersData.filter((user) => {
                    const userRole = sdkWrapper.getRoleName(user);

                    if (userRole === 'Administrator' || userRole === 'Admin') {
                        return true;
                    } else {
                        return false;
                    }
                });
                const users = usersData.filter((user) => {
                    const userRole = sdkWrapper.getRoleName(user);

                    if (userRole === 'Gebruiker') {
                        return true;
                    }
                });

                setTotalUsers(usersData.length);
                setAdminCount(admins.length);
                setUserCount(users.length);
            }
        }

        async function fetchGames() {
            let roleId = '';
            if (typeof user.role === 'string') {
                roleId = user.role;
            } else {
                roleId = user.role.id;
            }
            const userRole = roles[roleId];

            sdkWrapper.getGames(true, userRole).then((response) => {
                setLevelData(response);
            }).catch((error) => {
                console.error('Error:', error);
                const HandledError = new Error(`Catched: ${(typeof error === 'string') ? error : error.message}`);
                Bugsnag.notify(HandledError);
            });
        }

        async function fetchData() {
            await fetchGames();
            await fetchUsers();
        }

        if (localStorageObject.isLoggedIn) {
            fetchData();
        }
    }, [localStorageObject.isLoggedIn]);


    return (
        <>
            <div className="my-10">
                {user && (<>
                    <h4>Hallo {user.first_name ?? ''} {user.last_name ?? ''}!</h4>
                    <p>Admin Dashboard</p>
                </>)}
            </div>
            <Card
                colors={{
                    bgMaterial: "bg-primary-300 dark:bg-primary-300",
                    textMaterial: "text-primary-800 dark:text-primary-800",
                }}
                className={`relative py-4 mx-0`}>
                <div className={`flex gap-4 flex-wrap`}>
                    <div className="h-16 w-16 rounded-full bg-primary-100 text-primary-800 flex">
                        <Icon className="m-auto" name='MdOutlinePeople' />
                    </div>
                    <div className="flex-1 flex flex-col gap-y-4">
                        <div>
                            <h4 className='mb-2'>{totalUsers} {plurification(totalUsers, 'Gebruiker', 'Gebruikers')}</h4>
                            <p>{adminCount} {plurification(adminCount, 'Admin', 'Admins')} | {userCount} {plurification(userCount, 'Gebruiker', 'Gebruikers')}</p>
                        </div>
                        <div className="flex gap-4 flex-wrap">
                            <div className='w-fit'>
                                <Button onClick={() => {router.push('/admin/users')}}>Beheren</Button>
                            </div>
                            <div className='w-fit'>
                                <Button onClick={() => {router.push('/admin/users/create')}}>Aanmaken</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
            
            {levelData && (
                <>
                    {/* <pre>
                        {JSON.stringify(levelData, null, 2)}
                    </pre> */}

                    <StackedCards cards={levelData} />
                </>
            )}
        </>
    )

	// return (
	// 	<>
	// 		<div>Admin dashboard</div>
	// 		{localStorageObject && (
	// 			<pre>
	// 				{JSON.stringify(localStorageObject, null, 2)}
	// 			</pre>
	// 		)}
	// 	</>
	// )
}
